import React from "react"
import { useStaticQuery, graphql } from "gatsby" // to query for image data
import { StaticImage } from "gatsby-plugin-image"
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    text:{
        paddingLeft: theme.spacing(1),
    },
}));

export default function Logo() {

    const { site } = useStaticQuery<GatsbyTypes.LogoQuery>(graphql`
    query Logo {
      site {
        siteMetadata {
            title
        }
      }
    }
  `);
    const classes = useStyles();

return (
    <Box display="flex" alignItems="center" justifyContent="center">
    <StaticImage src="../../images/icon_white.png" 
        alt="Credit Comparator logo"
        layout="fixed"
        placeholder="blurred"
        width={24}
        height={24} />
        <Typography className={classes.text} component="h1" variant="h6" color="inherit" noWrap >
            {site.siteMetadata.title}
          </Typography>
        </Box>
);
}