import React, { useState } from "react";

import { Container, makeStyles } from "@material-ui/core";
import TopBar from "../../organisms/TopBar";
import clsx from "clsx";
import Footer from "../../organisms/Footer";
import { CSSProperties } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.content,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 48,
    },

    // This style is required for Nav Bar, left here as it is temporary disabled
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: 256
    // },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
  layoutPaddings: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 0),
    },
  },

  main: {
    flex: "1 1 100%",
    backgroundColor: theme.palette.background.content,
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(3),
    padding: theme.spacing(2, 0, 3, 0),
  },

  footer: {
    padding: theme.spacing(3, 2),
    backgroundColor: theme.palette.background.default,
    flex: "1 1 auto",
    marginTop: "auto",
  },
}));

const MainLayout: React.FC<{}> = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        className={classes.layoutPaddings}
      />
      {/* Nav bar is left here to easily enable it when needed */}
      {/* <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        /> */}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <main className={clsx(classes.main, classes.layoutPaddings)}>
              <Container maxWidth="md">
                <>{children}</>
              </Container>
            </main>

            <div className={clsx(classes.footer, classes.layoutPaddings)}>
              <Footer></Footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
