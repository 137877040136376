import React from "react";
import clsx from 'clsx';
import { Box, Container, makeStyles, Typography, useTheme } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from "gatsby"


const useStyles = makeStyles((theme) => ({
    root:{
        
    },
    item:{
        padding: theme.spacing(0.2, 1),
        color: theme.palette.text.secondary,
    },
    link: {
        textDecoration: 'underline',
        
    },

}));

export default function Footer() {
    const theme = useTheme();
    const classes = useStyles();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <footer>
            <Typography component="div" variant="body2"> 
            <Box display="flex" 
                flexDirection={matches ? "row" : "column"} 
                justifyContent="center"
                className={classes.root}
                >
                {/* <Link className={clsx(classes.link, classes.item)} to="/privacy-policy" >Politica de confidentialitate</Link> */}
                <Link className={clsx(classes.link, classes.item)} to="/politica-de-utilizare-cookie-uri">Politica de utilizare Cookie-uri</Link>
                {/* <Link className={clsx(classes.link, classes.item)} to="/terms-and-conditions">Termeni si conditii</Link> */}
                {/* <Link className={clsx(classes.link, classes.item)} to="/contacts">Contact</Link> */}
                <Typography className={classes.item}  variant="body2" >{process.env.GATSBY_APP_VERSION ?? 'missing version'}</Typography>
            </Box>
            </Typography>
        </footer>
    );

};